#mainDiv {
    margin-top: 110px;
    padding: 30px;
    background: white;
}



.imgSourceClass {
    display: block;
    text-align: left;
}

.imgSourceClass img {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 10px;
    vertical-align: middle;
}

.choiceImg {
    margin-bottom: 0px !important;
}

@media (max-width: 767px) {

    .imgSourceClass {
        text-align: left;
        width: 100%;
    }

    .imgSourceClass img {
        display: block;
    }

    .container {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .row,
    .col-md-10 {
        width: 100%;
        max-width: none;
        padding: 0;
        margin: 0;
    }

    .card,
    .card-body {
        padding: 0;
        margin: 0;
    }

    .imgSourceClass {
        width: 100%;
    }
    .choiceImg {
        margin-bottom: 10px !important;
    }
}