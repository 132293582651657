#mainDivNew {
    margin-top: 110px;
}

#imgUploaded {

    max-width: 90%;
    display: block;
    margin: auto;
    padding: 2px;
    object-fit: contain;
}

.button-right {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}


.halfImage {
    width: 20% !important;
    height: 20% !important;
}

.fullProjectImage {
    width: 60% !important;
    height: 60% !important;
    margin: auto;
}


.input-inactive {
    color: gray !important;
    text-decoration: none !important;
    padding: 10px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    border-left: 1px solid #EEE;
    border-right: 1px solid #EEE;
    border-top: 1px solid #EEE;
}


.input-active,
.input-active:hover {
    color: blue !important;
    border-left: 1px solid #AAAAFF;
    border-right: 1px solid #AAAAFF;
    border-top: 1px solid #AAAAFF;
    border-radius: 2px;
    padding: 10px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.gray {
    color: gray !important;
}

textarea::placeholder {
    opacity: 0.5 !important;
}

#raw_prompt {
    color: #555;
    padding: 20px;
    border: 1px solid #EEE;
    margin: 20px;
}

#cellsColors {
    font-size: 1.2em;
    background-color: #FCFCFC;
    border: 1px solid #EEE;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    color: #777;
}

.hint {
    color: #565656;
    margin-bottom: 20px;
}

.imgSourceClass {
    border: 1px solid #DDD;
    border-radius: 6px;
    margin-top: 20px;
    margin-right: 20px;
}

.container-input-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.form-input-label {
    margin-bottom: 10px;
}

#surprizeMe {
    padding: 7px;
    font-size: 0.9em;
}

@media (max-width: 768px) {
    #surprizeMe {
        width: 100px !important;
    }

    .imgInputType {
        width: 75px !important;
        margin: 5px !important;
    }
}

@media (min-width: 768px) {

    .container-input-label {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: left;
    }

    .form-input-label {
        margin-bottom: 0;
    }

    .choiceImg {
        margin-bottom: 10px !important;
    }
}

.choiceImg {
    margin-bottom: 0px !important;
}