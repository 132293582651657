#wholeProjectsContainer {
    margin-top: 90px;
    margin-left: 10px;
}

#projectsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    margin-top: 5px;
    width: 99%;
}

@media (max-width: 768px) {
    #projectsContainer {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}

.project-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0.4rem;
    padding-top: 0px;
    padding-bottom: 0px;
    box-sizing: border-box;
    background-color: #FFF;
    border: 1px solid #BBB;
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.3);
    border-bottom: 2px solid transparent !important;
    width: 100%;
}

.project-item-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
}

.project-item img {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 120px;
    margin-top: 10px;
}

.project-item.private {
    border-bottom: 3px solid green !important;
}

.project-header {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
}

.custom-tab-container .nav-link {
    background-color: #f8f9fa;
    margin-right: 2px;
    padding: 10px 15px;
    color: #777;
    width: 140px;
    text-align: center;
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 0px;
}

.custom-tab-container .nav-link.active {
    color: #495057;
    background-color: #DDD;
    font-weight: bold;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    color: #000;
    border-bottom: 0px;
}

.star-icon {
    position: absolute;
    top: 4px;
    left: 4px;
    cursor: pointer;
    font-size: 1.2em;
    z-index: 1;
}

.kind-icon {
    top: 20px;
    right: 0px;
    z-index: 1000;
    position: absolute;
    display: inline-block;
    background: linear-gradient(45deg, #EEE, #FFF);
    padding: 1px 4px;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-family: 'Arial', sans-serif;
    font-size: 0.75em;
    text-transform: uppercase;
    letter-spacing: 1px;
    transform: rotate(45deg);
    transform-origin: center;
}


.starred {
    color: gold;
}

.not-starred {
    color: lightgray;
}

.how_to_star {
    width: 30lvw;
}

.login_hint {
    margin-top: 130px;
    text-align: center;
}

@media (max-width: 768px) {
    .how_to_star {
        width: 90%;
    }


}

#tagsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 10px;
    align-items: baseline;
    border: 1px solid #DDD;
    margin-top: 10px;
    width: 99%;
    background-color: #EEE;
    border-radius: 3px;
}

.tag {
    background-color: #FFF;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 0px;
    display: inline-block;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 0.95em;
    border: 1px solid #DDD;
}

.tag.enabled {
    background-color: #4CAF50;
    color: white;
    border-color: #3C9F40;
}

.tag.disabled {
    background-color: #FFF;
    color: #333;
    border-color: #DDD;
}

.tag:hover {
    cursor: pointer;
    transform: scale(1.04);
    border-color: #3C9F40;
}

.pattern-class {
    background-color: #FFFFF4;
}

.image-class {
    background-color: #F4FFFF;
}

#buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
}

.btn_small {
    font-size: 0.9em !important;
    padding: 4px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    color: #333;
    border: 1px solid #AAA !important;
}

#buttonsContainer button {
    flex: 0 0 auto;
    margin-right: 10px;
}

@media (max-width: 768px) {
    #buttonsContainer button {
        width: 140px;
    }
}