body {
  background-color: #DDD !important;
}

.App {
  text-align: center;
}

.main-content {
  padding-top: 0px;
}