.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
}

.navbar {
    background-color: #FFF !important;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.3);
}

.nav-link {
    margin-right: 10px;
}