.setup {
    background: #EEE;
    border: 1px solid #CCC;
    padding: 20px;
    margin-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
}

.color-mapping-container {
    display: flex;
    flex-direction: column;
}

.color-mapping-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.color-label {
    margin-right: 4px;
    min-width: 160px;
    max-width: 200px;
    /* Adjust the label width as needed */
}

.color-input {
    flex-grow: 1;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: white;
    color: #000;
}

.color-mapping-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.button-right {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

#leftSideButtons {
    margin-left: 100px;
}

#resetButton {
    margin-left: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    #cancelButton {
        margin-bottom: 15px !important;
        margin-top: 15px;
    }

    .color-label,
    .color-input {
        width: 100%;
        /* Full width on smaller screens */
    }

    .color-mapping-item {
        flex-direction: column;
        /* Stack label and input vertically on small screens */
        align-items: flex-start;
    }

    .button-right {
        justify-content: center;
        /* Center buttons on smaller screens */
    }

    #leftSideButtons {
        justify-content: center !important;
        width: 100%;
    }

    #leftSideButtons {
        margin-left: 0px !important;
    }

    #resetButton {
        margin-left: 0px !important;
        margin-bottom: 15px !important;
    }
}