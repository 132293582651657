#detailsImageContainer {
    touch-action: manipulation;
    position: relative;
    cursor: pointer;
    text-align: center;
    height: 99%;
    margin: 20px auto;

}
#detailsImage {
    height: calc(100vh - 260px);
    width: auto;
}


.detailsProjectHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #FFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    left: 0;
    width: 100%;
}

.detailsProjectTitle {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}


@media (max-width: 768px) {
    .detailsProjectHeader {
        padding: 10px;
    }

    .detailsProjectTitle {
        font-size: 1.2rem;
    }

    .btn {
        margin: 0px 0;
        width: 100%;
    }

}