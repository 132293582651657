#imageEditContainer {
    touch-action: manipulation;
    width: 99%;
    margin: 20px auto;
    text-align: center;
    max-height: calc(100vh - 220px);
    overflow-y: scroll;
    overflow-x: scroll;
}

#imageContainer {
    touch-action: manipulation;
    position: relative;
    cursor: pointer;
    width: 99%;
    margin: 20px auto;

}

.matrix-row {
    display: flex;
    margin-bottom: 0;
}

.matrix-color {
    flex-grow: 1;
    cursor: pointer;
    box-sizing: border-box;

}



.button-container-editor {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid #ccc;
    padding: 10px;

}

.button-right {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-top: 6px;
}

.btn {
    margin: 5px;
}

.btnColorSet {
    width: 36px;
    height: 36px;
    border: 1px solid gray;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 50%; 
}


.btnColorActive {
    border: 3px outset #EEE;
    transform: scale(1.3);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.btnTool {
    margin: 5px;
    width: 46px;
    height: 46px;
    cursor: pointer;
    border: 1px ridge #EEE;

}

.btnToolActive {
    border: 4px outset #EEE;
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.realImgBtn {
    margin: 5px;
    width: 50px;
    height: 50px;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s, transform 0.3s;
}


.realImgBtn:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(2px);
}

.colorBtn {
    margin: 5px;
    width: 36px;
    height: 36px;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s, transform 0.3s;
    
}

.colorBtn:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(2px);
}

.colorPickerDialog {
    position: absolute;
    z-index: 2000;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    width: 50%;
    min-width: 600px;
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
    .colorPickerDialog {
        width: 100%;
        min-width: 0;
    }
}

.predefinedColors {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.predefinedColor {
    width: 40px;
    height: 40px;
    margin: 5px;
    cursor: pointer;
    border: 1px solid #888;
    border-radius: 50%;

}


.replaceColorsDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    z-index: 500;
    width: 90%;
    max-width: 600px;
    box-sizing: border-box;
}

.replaceColorsContainer {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.colorSelection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    
}

.colorSelection h4 {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
}

.colorGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.colorItem {
    width: 40px;
    height: 40px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: border 0.3s;
    border-radius: 50%;
}

.colorItem.selected {
    border: 2px solid #000;
}

.button-right {
    display: flex;
    justify-content: space-between;
}

.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-secondary {
    background-color: #ccc;
}

.btn-primary {
    background-color: #007bff;
    color: white;
}

@media (max-width: 600px) {
    .replaceColorsDialog {
        width: 100%;
        padding: 10px;
    }

    .colorItem {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .button-right {
        flex-direction: column;
        gap: 10px;
    }

    .colorSelection {
        width: 100%;
        margin-bottom: 10px;
    }
}
