#instruction {
    font-size: 1.5em;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.firstRow {
    flex-grow: 1;
    text-align: left;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

}

#imageContainer {
    touch-action: manipulation;
    position: relative;
    cursor: pointer;
    width: 99%;
    margin: 20px auto;
}

#image {
    width: 100%;
    height: auto;
}

#editImage {
    width: 80%;
    height: auto;
}

.highlight {
    background-color: #99CCFF;
    border: 1px solid #1155FF;
    padding: 5px;
}

#rowOverlay {
    pointer-events: none;
    background-color: rgba(153, 204, 255, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    z-index: 1;
}

#instructionOverlay {
    pointer-events: none;
    background-color: rgba(255, 100, 0, 0.7);
    border: 2px solid red;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    z-index: 2;
}



.button-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.moveButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 5px;
    font-size: 1em !important;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    flex-basis: content;
}


.moveButton:active {
    background-color: #004085;
    transform: scale(0.95);
}

@media (max-width: 768px) {
    #instruction {
        flex-direction: column;
        align-items: flex-start;
    }

    .instructionText {
        margin-left: 0;
        margin-top: 10px;
        white-space: normal;
    }
}

.projectHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #FFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    left: 0;
    width: 100%;
}

.projectTitle {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.setupIcon {
    width: 30px;
    height: auto;
    cursor: pointer;
}

.btn {
    margin: 0 10px;
    padding: 5px 15px;
}


@media (max-width: 768px) {
    .projectHeader {
        padding: 10px;
    }

    .projectTitle {
        font-size: 1.2rem;
    }

    .btn {
        margin: 0px 0;
        width: 100%;
    }

    .setup {
        padding: 10px;
        margin-top: 80px;
    }
}

@media (max-width: 768px) {
    .firstRow {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

}

.instructionContainer {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 10px 15px;
    font-size: 1.1rem;
    color: #495057;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 1.5em;
    width: 30vw !important;
}

.moveButtonContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .instructionContainer {
        flex-direction: column;
        width: 80vw !important;
    }

    .moveButtonContainer {
        flex-direction: row;
    }

    .moveButton {
        width: 100%;
        margin-bottom: 10px;
    }
}